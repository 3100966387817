export const ConstValues = {
  PRINT_PENDING: 'PENDING',
  PRINT_OPEN: 'OPEN',
  PRINT_CREATED: 'OPEN',
  PRINT_SENTTOPRESS: 'OPEN',
  PRINT_ONDIECUTTER: 'OPEN',
  PRINT_ATREWINDER: 'OPEN',
  PRINT_WAITINGTOBECUT: 'OPEN',
  PRINT_SHIPPING: 'OPEN',
  PRINT_SHIPPED: 'OPEN',
  PRINT_READYTOINVOICE: 'OPEN',
  PRINT_WAITINGONPAYMENT: 'OPEN',
  PRINT_INVOICECREATED: 'OPEN',
  PRINT_PAIDINTRANSIT: 'OPEN',
  PRINT_CLOSED: 'OPEN',
  DELIVERY_OPTIONS: {
    DEFAULTRUSHLEVEL1DELIVERYOPTIONID: 6,
    DEFAULTRUSHLEVEL2DELIVERYOPTIONID: 4,
    DEFAULTRUSHLEVEL4DELIVERYOPTIONID: 3,
    DEFAULTNONINTERNATIONALOPTIONID: 1,
    DEFAULTRUSHFORINTERNATIONALOPTIONID: 8,
    DEFAULTFORINTERNATIONALOPTIONID: 9
  },
  DEFAULT_COUNTRY_ID: 1,
  COUNTRY_USA: 'USA',
  CUSTOMER_PICKUP_ADDRESS_ID: '72E1C510-28A9-41DE-8565-527B34F1B848',
  NEW_ORDER_PO: 'newOrderPo'
};

export const enum NoAccessTypes {
  NotConfirmed = 0,
  Disabled,
  CreditHold
}

export const enum VarDataDynamicTextTypes {
  None = 0,
  PublicCode = 1,
  UPC = 2,
  SKU = 3,
  ASIN = 4,
  Custom = 5,
  PartNumber = 6,
  Ean13 = 7,
  FNSKU = 8,
  Ean8 = 9,
  Ean14 = 10,
  Ucc14 = 11
}
